<template>
  <div class="footer-container">
    <div class="wrap content">
      <!-- 左侧信息 -->
      <div class="footer-left">
        <div class="logo">
          <img
            :src="contactInfo.logo ? baseURL + contactInfo.logo : ''"
            alt=""
          />
        </div>
        <div class="info">
          <div class="info-item">
            <span class="info-item-label">联系电话：</span>
            <span class="info-item-value">{{ contactInfo.phone }}</span>
          </div>
          <div class="info-item">
            <span class="info-item-label">售后服务：</span>
            <span class="info-item-value">{{ contactInfo.afterSales }}</span>
          </div>
          <div class="info-item">
            <span class="info-item-label">比昂科技客户之声电话：</span>
            <span class="info-item-value">173 8059 9635</span>
          </div>
          <div class="info-item">
            <span class="info-item-label">比昂科技客户之声邮箱：</span>
            <span class="info-item-value">3835423001@qq.com</span>
          </div>
          <div class="info-item">
            <span class="info-item-label">公司地址：</span>
            <span class="info-item-value">{{ contactInfo.address }}</span>
          </div>
          <div class="info-item">
            <span class="info-item-label">生产基地：</span>
            <span class="info-item-value">{{ contactInfo.base }}</span>
          </div>

          <!-- 微信二维码 -->
          <div class="network-contact">
            <!-- <el-popover
                            placement="bottom"
                            trigger="hover">
                            <div class="QR-code">
                               <div class="qr-code-item" v-for="(val,i) in contactInfo.picList" :key="i">
                                    <img :src="baseURL + val.picUrl" alt="">
                                    <span>{{val.picName}}</span>
                               </div>
                            </div>
                            <div slot="reference" class="weixin">
                                <img src="@/assets/images/footer/weixin.png" alt="">
                            </div>
                        </el-popover> -->
            <div
              class="qr2-code-item"
              v-for="(val, i) in contactInfo.picList"
              :key="i"
            >
              <img :src="baseURL + val.picUrl" alt="" />
              <span
                style="color: rgba(255, 255, 255, 0.6); line-height: 35px"
                >{{ val.picName }}</span
              >
            </div>

            <!-- <el-popover
                            placement="bottom"
                            trigger="hover">
                            <div class="QR-code">
                               <div class="qr-code-item">
                                    <img src="@/assets/images/xcx_code.jpg" alt="">
                                    <span>微信小程序</span>
                               </div>
                                <div class="qr-code-item">
                                    <img src="@/assets/images/xcx_code.jpg" alt="">
                                    <span>微信小程序</span>
                               </div>
                                <div class="qr-code-item">
                                    <img src="@/assets/images/xcx_code.jpg" alt="">
                                    <span>微信小程序</span>
                               </div>
                            </div>
                            <div slot="reference" class="weixin">
                                <img src="@/assets/images/footer/weibo.png" alt="">
                            </div>
                        </el-popover> -->
          </div>
        </div>
      </div>

      <!-- 右侧导航 -->
      <div class="right-nav">
        <div class="nav-item">
          <h1>产品中心</h1>
          <div class="nav-list">
            <div
              v-for="(item1, index1) in productList"
              :key="index1"
              @click="pageJumps(`/ProductList?type=${item1.productName}`)"
            >
              {{ item1.productName }}
            </div>
          </div>
        </div>

        <div class="nav-item">
          <h1>解决方案</h1>
          <div class="nav-list">
            <div
              v-for="(item2, index2) in solutionList.slice(0, 5)"
              :key="index2"
              @click="pageJumps(`/solutionDetails?id=${item2.id}`)"
            >
              {{ item2.solutionName }}
            </div>
            <div class="more" v-if="solutionList.length > 5">
              查看更多<i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div class="nav-item" v-if="false">
          <h1>经典案例</h1>
          <div class="nav-list">
            <div
              v-for="(item3, index3) in caseList.slice(0, 5)"
              :key="index3"
              @click="pageJumps(`/caseDetails?id=${item3.id}`)"
            >
              {{ item3.caseName }}
            </div>
            <div class="more" v-if="caseList.length > 5">
              查看更多<i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div class="nav-item">
          <h1>新闻中心</h1>
          <div class="nav-list">
            <div
              v-for="(item4, index4) in newsTypeList"
              :key="index4"
              @click="pageJumps(`/dynamicInfo?id=${item4.dictValue}`)"
            >
              {{ item4.dictLabel }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 版权信息 -->
    <div class="wrap copyright">
      <div>{{ contactInfo.copyRight }}</div>
      <div class="beian" @click="beian">{{ contactInfo.filing }}</div>
    </div>
  </div>
</template>
<script>
import { baseURL } from "@/utils/request";
import {
  contactInfo,
  solutionList,
  productList,
  caseList,
  newsTypes,
} from "@/api/footer";
export default {
  name: "",
  data() {
    return {
      baseURL,
      contactInfo: {},
      productList: [
        { id: "1001", productName: "五情监测" },
        { id: "1002", productName: "太阳能杀虫灯" },
        { id: "1003", productName: "粘虫板/诱蝇球/诱捕器" },
      ],
      solutionList: [],
      caseList: [],
      newsTypeList: [],
    };
  },
  created() {},
  mounted() {
    this.getContactInfo();
    this.getSolutionList();
    // this.getProductList()
    this.getCaseList();
    this.getNewsTypes();
  },
  methods: {
    // 获取底部联系信息
    getContactInfo() {
      contactInfo().then((res) => {
        // console.log(res,"版权信息")
        this.contactInfo = res.data[0];
      });
    },

    // 获取产品数据
    getProductList() {
      productList().then((res) => {
        // console.log(res,"产品列表")
        this.productList = res.rows;
      });
    },

    // 获取解决方案数据
    getSolutionList() {
      solutionList().then((res) => {
        // console.log(res,"解决方案")
        this.solutionList = res.rows;
      });
    },

    // 获取应用案例数据
    getCaseList() {
      caseList().then((res) => {
        console.log(res, "应用案例kkkkkkkkkkk");
        this.caseList = res.rows;
      });
    },

    // 获取新闻分类
    getNewsTypes() {
      newsTypes().then((res) => {
        this.newsTypeList = res.data;
      });
    },

    pageJumps(path) {
      this.$router.push({ path }, () => {});
    },
    beian() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.footer-container {
  background: rgba(31, 45, 61, 1);
  padding: 72px 0 20px 0;
  .content {
    display: flex;
  }
  .footer-left {
    flex: 1.3;
    .logo {
      height: 54px;
      img {
        height: 100%;
      }
    }
    .info {
      margin-top: 32px;
      .info-item {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 12px;
      }
    }
    .network-contact {
      display: flex;
      .weixin {
        margin-right: 12px;
        width: 16px;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
  }

  // 右侧导航
  .right-nav {
    flex: 1.5;
    display: flex;
    justify-content: space-between;

    .nav-item {
      h1 {
        font-size: 28px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.8);
      }
      .nav-list {
        margin-top: 24px;
        > div {
          font-size: 20px;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 12px;
          cursor: pointer;
        }
        .more {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.8);
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }

  // 版权信息
  .copyright {
    margin-top: 16px;
    padding-top: 16px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: rgba(209, 209, 209, 0.6);
    border-top: 1px solid rgba(204, 204, 204, 0.1);
    .beian {
      cursor: pointer;
      &:hover {
        color: rgba(209, 209, 209, 1);
      }
    }
  }
}
</style>

<style lang="less">
.el-popover {
  .QR-code {
    display: flex;
    .qr-code-item {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      align-items: center;
      img {
        width: 56px;
      }
      span {
        margin-top: 6px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
.qr2-code-item {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  align-items: center;
  img {
    width: 100px;
    height: 1009x;
  }
}
</style>
